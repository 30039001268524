import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Paper,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Button,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import ExcelDownload from "../../Excel/ExcelDownload";
import axios from "axios";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { fetchViewAdminAllClient } from "../../../Redux/Slice/Admin/Report/ViewAllClientDataSlice";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

function ErrorComponent({ message }) {
  return <div style={{ color: "red" }}>{message}</div>;
}

export default function AdminReport() {
  const dispatch = useDispatch();

  const visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );

  const AllData = useSelector(
    (state) =>
      state.ViewAllClientData.ViewAdminAllClientData?.data?.AllData || []
  );

  const userOptions = [...new Set(AllData.map((item) => item.empname))];

  const [selectedUser, setSelectedUser] = React.useState([]);

  const loading = useSelector((state) => state.ViewAllClientData.isLoader);
  const error = useSelector((state) => state.ViewAllClientData.isError);

  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  );

  React.useEffect(() => {
    dispatch(fetchViewAdminAllClient());
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewAdminVisits());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = debounce((event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  }, 300);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const generateYearRange = (numYears) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: numYears }, (_, index) => currentYear - index);
  };

  const yearOptions = generateYearRange(5);

  const filteredData = React.useMemo(() => {
    return (
      AllData?.filter(
        (client) =>
          (selectedEmpCodes.length === 0 ||
            selectedEmpCodes.includes(client?.emp_code)) &&
          (selectedUser.length === 0 ||
            selectedUser.includes(client?.empname)) &&
          (client?.empname?.toLowerCase().includes(searchTerm) ||
            client?.name?.toLowerCase().includes(searchTerm))
      )
        // Sorting based on categoery_id first and then alphabetically by name
        .sort((a, b) => {
          if (parseInt(a.categoery_id) !== parseInt(b.categoery_id)) {
            return parseInt(a.categoery_id) - parseInt(b.categoery_id);
          } else {
            return a.name.localeCompare(b.name);
          }
        })
    );
  }, [AllData, visits, selectedEmpCodes, selectedUser, searchTerm]);

  // console.log("all data " + JSON.stringify(AllData))
  // console.log("filtered data " + JSON.stringify(filteredData))

  const slicedData = React.useMemo(() => {
    return filteredData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [filteredData, page, rowsPerPage]);

  // console.log("sliced data " + JSON.stringify(slicedData));

  const categoryMapping = {
    Distributor: "1",
    Dealer: "0",
    Architect: "5",
    Builder: "6",
    Contractor: "7",
    Competitor: "8",
    Other: "10",
  };

  const getVisitCount = (clientData, month) => {
    return visits?.reduce((count, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear
      ) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const getOrderYesCount = (clientData, month) => {
    return visits?.reduce((count, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear &&
        visit.visits.payment_status === "Yes"
      ) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const getTotalAmount = (clientData, month) => {
    return visits?.reduce((total, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear
      ) {
        // Convert amount to number and add it to total
        return total + parseFloat(visit.visits.amount || 0);
      }
      return total;
    }, 0);
  };

  const [downloadExcel, setdownloadExcel] = React.useState(false);
  const [excelData, setexcelData] = React.useState();

  // download excel
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };
  const [tableData, setTableData] = React.useState([]);
  // console.log("tableData", tableData);
  React.useEffect(() => {
    const newData = [];
    slicedData?.forEach((clientData, index) => {
      const rowData = Array.from({ length: 12 }, (_, index) => {
        const visitCount = getVisitCount(clientData, index);
        const orderCount = getOrderYesCount(clientData, index);
        const totalAmount = getTotalAmount(clientData, index);
        return { visitCount, orderCount, totalAmount };
      });
      newData.push(rowData);
    });
    setTableData(newData);
  }, [slicedData]);
  const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
  };
  const sendTableDataToAPI = async () => {
    const token = getTokenFromLocalStorage();
    try {
      const response = await axios.post(
        "https://api.appris.in/public/api/email-report",
        tableData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response:", response.data);
      // Handle success response from API if needed
    } catch (error) {
      console.error("Error:", error);
      // Handle error response from API if needed
    }
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            padding: "20px",
            // height: "50px", 
          }}
        >
          {/* Left Section - Download Button & Year Filter */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button variant="contained" color="primary" onClick={donloadexcel}>
              Download Excel
            </Button>
            <FormControl>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                displayEmpty
                style={{ backgroundColor: "#fff", height: "50px" }}
              >
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Center Section - Report Heading */}
          <div style={{ textAlign: "center", flex: "1" }}>
            <h4 style={{ color: "#ffffff", margin: 0 }}>Reports Details</h4>
          </div>

          {/* Right Section - Select User & Search */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={userOptions}
              disableCloseOnSelect
              onChange={(event, newValue) => setSelectedUser(newValue)}
              value={selectedUser}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ minWidth: "150px", height: "50px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "5px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    style={{
                      width: "100%",
                      minWidth: "150px",
                      borderRadius: 3,
                      padding: 2,
                      height: "40px",
                    }}
                    placeholder="Select User"
                  />
                </Paper>
              )}
            />
            <InputBase
              placeholder="Search by Emp Name or Client Name"
              onChange={handleSearch}
              style={{
                width: "200px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                height: "50px",
              }}
            />
          </div>
        </div>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <ErrorComponent message={error.message} />
        ) : filteredData && filteredData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 0,
                        zIndex: 100,
                        width: "80px", // Adjust width
                        minWidth: "80px",
                      }}
                    >
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 80, // Adjust based on previous column width
                        zIndex: 100,
                        width: "150px",
                        minWidth: "150px",
                      }}
                    >
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 230, // Adjust
                        zIndex: 100,
                        width: "120px",
                        minWidth: "120px",
                      }}
                    >
                      Category
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 350,
                        zIndex: 100,
                        width: "120px",
                        minWidth: "120px",
                      }}
                    >
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 470,
                        zIndex: 100,
                        width: "150px",
                        minWidth: "150px",
                        borderRight: "1px solid #fff",
                      }}
                    >
                      Emp Name
                    </StyledTableCell>
                    {Array.from({ length: 12 }, (_, index) => {
                      const monthName = new Date(2024, index).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      );
                      return (
                        <React.Fragment key={index}>
                          <StyledTableCell
                            colSpan={3}
                            style={{
                              backgroundColor: "#7b0087",
                              textAlign: "center",
                              borderRight: "1px solid #fff",
                            }}
                          >
                            {monthName}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>

                  <TableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 0,
                        zIndex: 100,
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 80,
                        zIndex: 100,
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 230,
                        zIndex: 100,
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 350,
                        zIndex: 100,
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        position: "sticky",
                        left: 470,
                        zIndex: 100,
                        borderRight: "1px solid #fff",
                      }}
                    ></StyledTableCell>
                    {Array.from({ length: 12 }, (_, index) => {
                      return (
                        <React.Fragment key={index}>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                            }}
                          >
                            Visit
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                            }}
                          >
                            Order
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                              borderRight: "1px solid #fff",
                            }}
                          >
                            Amount
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {slicedData?.map((clientData, index) => (
                    <StyledTableRow key={clientData.id}>
                      <StyledTableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 100,
                          width: "80px", // Adjust width
                          minWidth: "80px",
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                        }}
                      >
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          position: "sticky",
                          left: 80, // Adjust based on previous column width
                          zIndex: 100,
                          width: "150px",
                          minWidth: "150px",
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                        }}
                      >
                        {clientData.name}
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          position: "sticky",
                          left: 230, // Adjust
                          zIndex: 100,
                          width: "120px",
                          minWidth: "120px",
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                        }}
                      >
                        {(() => {
                          switch (String(clientData?.categoery_id)) {
                            // case "0":
                            //   return "Distributor";
                            case "1":
                              return "Dealer";
                            case "5":
                              return "Architect";
                            case "6":
                              return "Builder/Contractor";
                            case "7":
                              return "Technician";
                            case "8":
                              return "Competitor";
                            case "10":
                              return "Other";
                            default:
                              return "Distributor";
                          }
                        })()}
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          position: "sticky",
                          left: 350,
                          zIndex: 100,
                          width: "120px",
                          minWidth: "120px",
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                        }}
                      >
                        {clientData?.emp_code}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          position: "sticky",
                          left: 470,
                          zIndex: 100,
                          width: "150px",
                          minWidth: "150px",
                          borderRight: "1px solid #fff",
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                          borderRight: "1px solid #E0E0E0",
                        }}
                      >
                        {clientData?.empname}
                      </StyledTableCell>
                      {tableData[index]?.map((rowData, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          <StyledTableCell>
                            {rowData.visitCount}
                          </StyledTableCell>
                          <StyledTableCell>
                            {rowData.orderCount}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            {rowData.totalAmount}
                          </StyledTableCell>
                        </React.Fragment>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </React.Fragment>
        ) : (
          <div>No data available</div>
        )}
        <div style={{ display: downloadExcel ? "block" : "none" }}>
          {downloadExcel ? (
            <ExcelDownload
              data={excelData}
              name="report-data"
              onsubmit={disabledownload}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
