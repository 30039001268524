import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewAdminProjects = createAsyncThunk(
  "ViewAdminProjects/fetchViewAdminProjects",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/project`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewAdminProjectsSlice = createSlice({
  name: "ViewAdminProjects",
  initialState: {
    ViewAdminProjectsData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewAdminProjects.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewAdminProjects.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewAdminProjectsData = action.payload;
      //   console.log("Viewcattt data:", action.payload);
    });
    builder.addCase(fetchViewAdminProjects.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewAdminProjectsSlice.reducer;
